
import {Rebuy} from "@root/_integrations/Rebuy";
import "./styles.scss";

// import gistAnalytics from "gist-analytics";

(function () {

  if (typeof GCP != "undefined") {
    return false;
  }

  if (window.location.href.includes('a/gc/gift-card')) {
    return false;
  }

  window.GCP = {

    selectors: {
      checkoutBtn: `button[name="checkout"],
      input[name="checkout"],
      a[href="/checkout"],
      form[action="/cart"] input[type="submit"],
      form[action="/cart"] button[type="submit"],
      form[action="/cart/"] input[type="submit"],
      form[action="/cart/"] button[type="submit"],
      form[action="/cart"] button:not([type="button"]),
      form[action="/cart/"] button:not([type="button"])`,
    },

    elements: {},

    /**
     * Internal setup functions.
     */
    _: {


      /**
       * Get settings based on the shop, and customer.
       */
      getSettings: () => {

        return new Promise((resolve, reject) => {
          let locale = typeof(Shopify.locale) != 'undefined' ? Shopify.locale : "";
          const url = `/a/gc/api/settings?locale=` + locale; // fetch(`${window.Shopify?.routes?.root}/settings?locale=` + locale)
          fetch(url)
          .then(res => res.json())
          .then((settings) => {
            resolve(settings);
          })
          .catch(err => {
            reject(err);
          });

        });

      },

      /**
       * Get the current type of page for the Shopify store.
       * Valid return values are easyaccounts_dashboard, account, product, cart, home, thank_you, collection
       */
      getPageType: () => {
        return new Promise((resolve, reject) => {

          if (meta.product && meta.product.id) {
            GCP.pageType = "product";
            resolve(GCP.pageType);
          }

          if (
            meta.page &&
            meta.page.pageType &&
            meta.page.pageType === "collection"
          ) {
            GCP.pageType = "collection";
            resolve(GCP.pageType);
          }

          if (window.location.href.includes("cart")) {
            GCP.pageType = "cart";
            resolve(GCP.pageType);
          }

          resolve("unknown");
        });
      },

      /**
       * Basic logger we can turn on and off for debugging
       */
      consoleLog: (v1, v2) => {
        GCP.debug = false;
        if (typeof(GCP.debug) != 'undefined' && GCP.debug === true) {
          console.log(v1, v2);
        }
      },

      /**
       * Call each module of the application, and setup anything that is needed.
       */
      bootstrap: (settings) => {

        return new Promise((resolve, reject) => {

          GCP._.consoleLog(settings);

          GCP.settings = settings;

          window.GIST = window.GIST || {};
          window.GIST.base_cdn_url = settings.base_cdn_url;
          window.GIST.s3_url = settings.s3_url;
          // app is enabled.
          if (!GCP.settings.enable) reject("Gift Card Pro is not enabled");

          resolve();

        })



      },

      /**
       * Send an error report to Gist gistAnalytics.
       */
      handleError: (error) => {
        console.warn(error);
        // gistAnalytics.insert("error", error.status, {
        //   shop_id: "{{shop.id}}",
        //   message: "API Error",
        //   url: `${process.env.PROXY_BASE_URL}/settings`,
        //   component: "easyaccount.js",
        //   body: "",
        //   stack: "front-end",
        //   location: window.location.href,
        // });
      },
    },

    /**
     * Initialize the application.
     */
    init: () => {

      GCP._.getPageType();

      GCP.cartPage.init();

      // Initialize product page
      GCP.productPage.init();

      GCP.global.init();

      // gistAnalytics.configure(process.env.GIST_ANALYTICS_API_KEY);

    },


  };

  /**
   * Add global non module related functions to the window.
   */
  GCP.f = {

    stopEvent: function(event, reason = null) {

      if (window.GIST && window.GIST.app_debug && window.GIST.app_debug === true) {
        console.log("Gift Card Pro is stopping event", reason, event);
      }

      if (typeof(event) === null) {
        return false;
      }
      if (event.preventDefault) {
          event.preventDefault();
      } else {
          event.stop();
      };
      event.returnValue = false;
      event.stopPropagation();
      event.stopImmediatePropagation();
    },
    /**
     * @param {String} HTML representing a single element
     * @return {Element}
     */
    htmlToElement: function(html) {
      var template = document.createElement('template');
      html = html.trim(); // Never return a text node of whitespace as the result
      template.innerHTML = html;
      return template.content.firstChild;
    },

    docReady: function (fn) {
      // see if DOM is already available
      if (
        document.readyState === "complete" ||
        document.readyState === "interactive"
      ) {
        // call on next available tick
        setTimeout(fn, 1);
      } else {
        document.addEventListener("DOMContentLoaded", fn);
      }
    },

    getHostName: function() {

      var hostName = "";
      var scriptTags = document.getElementsByTagName('script');

      for (const scriptTag of scriptTags) {

        if (hostName !== "") return hostName;

        if ((scriptTag.src).includes('giftcardpro.app')) {
          hostName = "https://cdn.giftcardpro.app";
        } else if ((scriptTag.src).includes('.ngrok.io')) {
          hostName = scriptTag.src.split(".ngrok.io")[0] + ".ngrok.io";
        }

      }

      return hostName;

    },

    getCSSPath: function(hostName) {

      var pathToCSS = hostName + "/build/storefront/storefront.css";

      if (typeof(window.GCPConfig) != 'undefined' && typeof(window.GCPConfig.cssURL) != 'undefined') {
        pathToCSS = window.GCPConfig.cssURL;
      }

      return pathToCSS;

    },

    loadStyles: function (reinit, callback) {

      if (reinit === true) {
        callback();
      }

      if (window.location.href.indexOf("checkout.shopify.com") >= 0) {
        return false;
      }

      const hostName = GCP.f.getHostName();

      const pathToCSS = GCP.f.getCSSPath(hostName);

      var styleSheets = document.styleSheets;

      for (var i = 1, max = styleSheets.length; i < max; i++) {
        if (styleSheets[i].href == pathToCSS) {
          // if stylesheets already exists, do callback and end here
          callback();
          return;
        }
      }

      var link = document.createElement("link");
      link.rel = "stylesheet";
      link.type = "text/css";
      link.href = pathToCSS;
      link.media = "all";

      document.getElementsByTagName("head")[0].appendChild(link);
      callback();
    },

    lastVisible: function (collection) {
      var el = undefined;

      if (collection.length <= 0) {
        return el;
      }

      for (let i = 0; i < collection.length; i++) {
        if (collection[i].offsetWidth > 0 && collection[i].offsetHeight > 0) {
          el = collection[i];
        }
      }

      return el;
    },

    firstVisible: function (collection) {
      var el = undefined;

      if (collection.length <= 0) {
        console.log("No elements found in collection");
        return el;
      }

      for (let i = 0; i < collection.length; i++) {
          if (typeof(el) == 'undefined' && collection[i].offsetWidth > 0 && collection[i].offsetHeight > 0) {
            el = collection[i];
          }
      }

      if (typeof(el) == 'undefined' && collection.length > 0) {
        el = collection[0];
      }

      return el;
    },

    /**
     * Grab the customer id from the window.
     */
    getCustomerId: () => {
      try {
        let curr = window.Shopify.meta.page.customerId;
        if (curr !== undefined && curr !== null && curr !== "") {
          return curr;
        }
      } catch (e) {}
      try {
        let curr = window.meta.page.customerId;
        if (curr !== undefined && curr !== null && curr !== "") {
          return curr;
        }
      } catch (e) {}
      try {
        let curr = _st.cid;
        if (curr !== undefined && curr !== null && curr !== "") {
          return curr;
        }
      } catch (e) {}
      return null;
    },

  };

  GCP.global = {

    init: () => {

      Rebuy.init();

    },

  };


  /**
   *
   */
  GCP.productPage = {

    state: {
      formValid: false,
      validationConfig: {}
    },
    elements: {
      form: GCP.f.firstVisible(
          document.querySelectorAll(`form[action*="/cart/add"]`)
      ),
      addToCart: GCP.f.firstVisible(
          document.querySelectorAll(`form[action*="/cart/add"] [type="submit"]`)
      )
    },

    init: function() {

      if (GCP.pageType !== "product") return;

      if (!meta || !meta.product.type || meta.product.type !== "GIST_GIFT_CARD") return;

      let $this = this;

      GCP._.getSettings()
      .then(GCP._.bootstrap)
      .then(() => {

        $this.events();

        $this.actions.giftCardRedirect();

        $this.actions.embedProductForm();

      })
      .catch(GCP._.handleError)
      ;

    },

    events: function() {

      GCP.productPage.elements.addToCart.addEventListener('click', function(e) {

        if (GCP.productPage.state.formValid === true) {
          // Set it back to false here so that the form can be submitted again
          GCP.productPage.state.formValid = false;
          return true;
        }

        GCP.f.stopEvent(e);
        GCP.productPage.actions.handleOnSubmit(e, GCP.SDKDATA.form, GCP.productPage.state.validationConfig);

      });

    },

    actions: {

      /**
       * Redirect to the proxy page purchase form.
       */
      giftCardRedirect: function() {

        if (GCP.settings.global.appearance.form_theme !== "product_template" && meta && meta.product && meta.product.type === "GIST_GIFT_CARD") {
          window.location = `${Shopify.routes.root || "/"}a/gc/gift-card/`;
        }

      },

      renderProductForm: async function(element) {

        const React = await import(/* webpackChunkName: "react" */ 'react');
        const ReactDOM = await import(/* webpackChunkName: "react-dom" */ 'react-dom/client');
        const {BrowserRouter} = await import(/* webpackChunkName: "react-router-dom" */ 'react-router-dom');

        const GiftCardForm = React.lazy(() => import('@proxy/pages/GiftCardForm'));
        const root = ReactDOM.createRoot(element);

        // Render within <Router> to allow use of useNavigate hook in GiftCardForm
        root.render(
          <BrowserRouter>
            <GiftCardForm
              preview={false}
              setEmailPreviewData={() => {}} // Hack to ensure this doesn't break.
            />
          </BrowserRouter>
        );
      },

      /**
       * Handle using the gift card product form in the theme template
       */
      embedProductForm: function() {

        if (GCP.settings.global.appearance.form_theme !== "product_template") return false;

        let locationEl = document.querySelector(".giftCardPro");
        const addToCartButton  = GCP.productPage.elements.addToCart;

        if (locationEl !== null) {
          return GCP.productPage.actions.renderProductForm(locationEl);
        }

        locationEl = GCP.f.htmlToElement('<div class="giftCardPro"></div>');

        if (typeof(addToCartButton) != 'undefined' ) {
          addToCartButton.before(locationEl);
        } else {
          console.warn("Could not find a suitable location to embed the Gift Card Pro purchase form. Please visit https://docs.giftcardpro.app for more troubleshooting information.");
        }

        GCP.productPage.actions.renderProductForm(locationEl);


      }

    }

  }

  /**
   * Checks to see if there is a gift card code in a cookie, if there is
   * an input will be created and appended with the gift card code as its
   * value so it can be easily applied at checkout
   */
  GCP.cartPage = {

    init: function() {

      if (GCP.pageType !== "cart") return;

      let $this = this;

      GCP._.getSettings()
      .then(GCP._.bootstrap)
      .then(() => {

        $this.events();

        $this.actions.handleTranslations();

        $this.actions.persistGiftCardCode();

      })
      .catch(GCP._.handleError)
      ;




    },

    events: function() {

    },

    actions: {

      /**
       */
      persistGiftCardCode: function() {

        const code = GCP.cartPage.actions.getDiscountCode();

        if (code === null) return;

        const input = GCP.cartPage.actions.createDiscountInput(code);

        GCP.cartPage.actions.embedDiscountInput(input);

      },

      /**
       * Look for all data-gcp-trans attributes, and replace them with the current
       * translation for the active locale.
       */
      handleTranslations: function() {
        // TODO: THIS FUNCTION
      },

      /**
       * Gets the gift card code from the discount_code cookie
       * returns - String on success or null on failure
       */
      getDiscountCode: function() {

        const discountCookie = document.cookie.split(";").filter(cookie => cookie.includes("discount_code"))[0];

        if (discountCookie != undefined) {
          return discountCookie.split("=")[1];
        }

        return null;

      },

      /**
       * Creates the discount input to be appended to the cart form
       * @param { string } code - The gift card code
       * returns - html input element
       */
      createDiscountInput: function(code) {

        const input = document.createElement("input");
        input.type  = "hidden";
        input.value = code;
        input.name  = "discount"

        return input;

      },

      /**
       * Embeds the input element into the cart form
       * @param { element } input - The input element to be appended to the form
       */
      embedDiscountInput: function(input) {

        const cartForm = document.getElementById("cart");
        cartForm.append(input);

      },

    }

  }



  GCP.f.loadStyles(false, function () {
    GCP.f.docReady(function () {
      GCP.init();
    });
  });

})();
